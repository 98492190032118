import React, { useMemo } from 'react';
import LayoutModal from './layout-modal';
import useModal, { useCurrentModal } from '@/hooks/modal';
import SignInForm from '@/components/Auth/signin-form';
import { useRouter } from 'next/navigation';
import ForgotPasswordForm from '@/components/Auth/forgot-pass-form';
import StandaloneModal from '../standalone-modal';
import { useAnalytics } from '@/hooks/use-analytics';

const SigninModal = ({
  isStandalone,
  setOpen,
}: {
  setOpen?: (param: any) => void;
  isStandalone?: boolean;
}) => {
  const { open: openModal, close } = useModal();
  const isOpen = useCurrentModal() === 'signin';
  const isOpenForgot = useCurrentModal() === 'forgot-pass';
  const router = useRouter();
  const { track } = useAnalytics();

  const signinContent = useMemo(() => {
    return isStandalone && isOpenForgot ? (
      <ForgotPasswordForm
        closeModal={() => {
          setOpen && setOpen(false);
          close();
        }}
        openLoginModal={() => {
          if (isStandalone) {
            router.push('/forms/sign_in');
          } else {
            open('signin');
          }
          track('forgot_password_form_started', {
            category: 'auth',
            label: window && window.location && window.location.href,
          });
        }}
      />
    ) : (
      <SignInForm
        isStandalone={isStandalone}
        closeModal={() => {
          setOpen && setOpen(false);
          close();
        }}
        openRegisterModal={() => {
          if (isStandalone) {
            router.push('/forms/sign_up');
          } else {
            openModal('signup');
          }
          track('signup_form_started', {
            category: 'auth',
            label: window && window.location && window.location.href,
          });
        }}
        openForgotPasswordModal={() => openModal('forgot-pass')}
        modalHeading={'Log In'}
      />
    );
  }, [close, isOpenForgot, isStandalone, openModal, router, setOpen, track]);

  if (isStandalone) {
    return <StandaloneModal>{signinContent}</StandaloneModal>;
  }

  return (
    <LayoutModal isOpen={isOpen} setIsOpen={setOpen}>
      {signinContent}
    </LayoutModal>
  );
};

export default SigninModal;
