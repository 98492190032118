'use client';

import React, { useCallback, useEffect, useState } from 'react';
import { Heading, FieldGroup, ErrorMessage } from '@purposeinplay/core-v2';
import { useLogin } from '@/hooks/auth';
import useModal from '@/hooks/modal';
import { cn } from '@/utils/style';
import { useTranslation } from '@/app/i18n/client';
import { AuthContentType } from '@/types/json-content/auth';
import { usePathname, useSearchParams } from 'next/navigation';
import SocialAuth from './components/social-auth';
import SignInFooter from './components/signin/footer';
import SignInFormFields from './components/signin/form-fields';
import OtpFormField from './components/signin/otp-form-field';
import useMarks from '@/hooks/marks';
import RestrictedMessage from './components/restricted-message';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  modalHeading?: string;
  closeModal?: () => void;
  openRegisterModal?: () => void;
  openForgotPasswordModal?: () => void;
  string?: string;
  isStandalone?: boolean;
}

export type StateType = {
  email: string | undefined;
  password: string | undefined;
  otp_attempt: string | undefined;
};

const initialState: StateType = {
  email: '',
  password: '',
  otp_attempt: '',
};

const LoginForm: React.FC<Props> = ({
  closeModal,
  openRegisterModal,
  openForgotPasswordModal,
  modalHeading,
  isStandalone = false,
}) => {
  const { close } = useModal();
  const [state, setState] = useState(initialState);

  const {
    mutateAsync: login,
    isError,
    error,
    isPending,
    reset,
    status,
  } = useLogin();
  const { t } = useTranslation();
  const authContent = t('auth') as AuthContentType;
  const content = authContent.signin_modal;
  const isLoading = status === 'pending';

  const [isRestricted, setIsRestricted] = useState(false);

  const searchParams = useSearchParams();
  const pathname = usePathname();
  const redirectUrl = searchParams.get('redirect_url');
  const [showOtp, setShowOtp] = useState(false);
  const { data: restrictions } = useMarks();
  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      setState(initialState);
      setIsRestricted(false);
      // we reset the marks query on close in case the user closed/opened the vpn
      queryClient.resetQueries({ queryKey: ['marks'] });
      reset();
    };
  }, [queryClient, reset]);

  useEffect(() => {
    // we need an extra showOtp state here to avoid flashing the email/pass inputs while the req is pending
    if (
      (isError &&
        error?.required &&
        error.required.includes('one_time_password')) ||
      error?.errors?.otp_attempt
    ) {
      setShowOtp(true);
    }
    if (isError && error?.errors?.otp_attempt?.invalid) {
      setState((prev) => ({ ...prev, otp_attempt: '' }));
    }
  }, [isError, error]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
      const target = e.target;
      setState((prevState) => ({ ...prevState, [type]: target.value }));
    },
    [],
  );

  const handleLogin = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const payload = { ...state };
      if (payload.otp_attempt?.length === 0) {
        delete payload.otp_attempt;
      }
      if (restrictions?.includes('auth_actions_forbidden')) {
        setIsRestricted(true);
        return;
      }
      try {
        const user = await login({ user: payload });

        close();
        if (redirectUrl) {
          window.location.assign(redirectUrl);
        } else if (pathname === '/signin') {
          window.location.assign('/');
        }

        if (user) {
          if (isStandalone) {
            window.parent.postMessage({ type: 'refresh_user' }, '*');
          }
          setState(initialState);
          closeModal?.();
        }
      } catch (e) {
        console.error('Login error:', e);
      }
    },
    [close, closeModal, login, pathname, redirectUrl, restrictions, state],
  );

  return (
    <div className={cn(['h-full', showOtp && 'md:h-[454px]'])}>
      <form
        className={cn([
          'h-full',
          showOtp && 'flex h-full flex-col justify-between',
        ])}
        onSubmit={handleLogin}
      >
        <div className={cn(['h-full', showOtp && 'flex flex-col'])}>
          <Heading
            as="h1"
            className={cn([
              'mx-auto mb-4 max-w-xs text-center text-2xl font-bold md:mb-7 md:max-w-xs',
              closeModal && 'mb-7',
            ])}
          >
            {modalHeading || (content && content?.heading)}
          </Heading>
          {!showOtp && <SocialAuth />}
          <FieldGroup>
            {!showOtp && (
              <SignInFormFields
                content={content}
                isError={isError}
                error={error}
                state={state}
                handleChange={handleChange}
                closeModal={closeModal}
                openForgotPasswordModal={openForgotPasswordModal}
              />
            )}
            <OtpFormField
              content={content}
              isError={isError}
              error={error}
              state={state}
              showOtp={showOtp}
              isLoading={isLoading}
              handleChange={handleChange}
            />
            {/* SS returned other error */}
            {!error?.errors && error?.message && (
              <ErrorMessage>{error?.message}</ErrorMessage>
            )}
            {isRestricted && <RestrictedMessage />}
            <SignInFooter
              closeModal={closeModal}
              openRegisterModal={openRegisterModal}
              isPending={isPending}
              content={content}
            />
          </FieldGroup>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
